import React from "react"
import LayoutEng from "../../components/LayoutEng"
import SEO from "../../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

const Contact = ({ data }) => {
  return (
    <LayoutEng>
      <SEO title="Contact" />
      <main className="page">
        <section className="contact-page">
          <article className="contact-info">
            <h3>Want To Get In Touch?</h3>
            <p>
            Please contact us with trust, and we will do our best to respond to you as soon as possible.
            </p>
            <p>
            Due to the specifics of the business, we would like to ask you to contact us only by e-mail, so as not to interfere with the ongoing consultations.
            </p>
            <div className="social-contact">
            <p><strong>Look for us on social networks</strong></p>
            <p>
            <div className="introImg">
                <div className="image-main lotusShadow">
                <StaticImage
                  src="../../assets/images/lotus1.png"           
                  id="lotus1"
                  alt="Apsiha"
                  placeholder="BLURRED"
                  //layout="constrained"
                ></StaticImage>
                </div>
                <div className="image-hover lotusShadow">
                  <StaticImage
                  src="../../assets/images/lotus2.png"            
                  id="lotus2"
                  alt="Apsiha"
                  placeholder="BLURRED"
                  //layout="constrained"
                ></StaticImage>
                </div>
                <div className="social-menu">
                    <ul>
                      <li><a href="https://www.facebook.com/apsiha/" target="blank" rel="noreferrer"><FontAwesomeIcon className="fab" icon={faFacebook} /></a></li>
                      <li><a href="https://www.instagram.com" target="blank" rel="noreferrer"><FontAwesomeIcon className="fab" icon={faInstagram} /></a></li>
                      <li><a href="https://www.youtube.com/channel/UC9p9gtmH4N4W5FESQ1_z95g" target="blank" rel="noreferrer"><FontAwesomeIcon className="fab" icon={faYoutube} /></a></li>
                    </ul>
                  </div>
                </div>
                </p>
                <br/>
            <p>Click on the icon to visit our Facebook page, Instagram or Youtube channel and connect with us in order to track interesting and new information related to the Center's work.</p>
            </div>         
          </article>
          <article>
          <h4>Send request</h4>
            <form className="form contact-form" action="../../api/contact.php" method='post' name="sentMessage" id="contactForm" novalidate >
              <div className="form-row">
                <label htmlFor="name">your name</label>
                <input type="text" name="name" id="name" className="form-control" placeholder="Your Name *" required data-validation-required-message="Please enter your name."/>
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-row">
                <label htmlFor="email">your email</label>
                <input type="email" name="email" id="email" className="form-control" placeholder="Your Email *" required data-validation-required-message="Please enter your email address."/>
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-row">
                <label htmlFor="message">message</label>
                <textarea name="message" id="message" className="form-control" placeholder="Your Message *" required data-validation-required-message="Please enter a message."></textarea >
                <p className="help-block text-danger"></p>
              </div>
              <div className="clearfix"></div>
              <div id="success"></div>
              <button type="submit" name="contactSubmit" className="btn block">
                submit
              </button>
            </form>
            <div className="dropdown-menu info-link">
                  <input type="checkbox" style={{ opacity: 0, position:'absolute', left:'-9999px'}} id="apsihaInfo" />
                  <label htmlFor="apsihaInfo">Apsiha j.d.o.o. Novi Zagreb</label>
                  <div className="info-content">
                      <ul>
                        <li> Apsiha j.d.o.o. </li>
                        <li> Adresa sjedišta: Ulica Karela Zahradnika 21, 10020 Novi Zagreb </li>
                        <li> Osnivači: Ana Miletić (direktor) i Ivana Mrgan </li>
                        <li> Osnovano: 2016. godine pri Trgovačkom sudu u Zagrebu </li>
                        <li> Temeljni kapital: 10,00 kn </li>
                        <li> OIB: 88717880071 </li>
                        <li> MB: 04558693 </li>
                        <li> Bankovni račun (OTP): HR4424070001100447286 </li>
                      </ul>
                  </div>
                </div>
          </article>
        </section>
      </main>
    </LayoutEng>
  )
}

export default Contact
